import styled from '@emotion/styled'

const NavList = styled.ul`
  padding: 0;
  list-style: none;
  width: 100%;
  margin: 0;
`

export default NavList
